import App from "@/App";
import { queryClient } from "@/client";
import ErrorFallback from "@/components/ErrorFallback";
import "@/style.css";
import "@precedent/chonky/dist/chonky.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { type ErrorInfo, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

(window as any).version = "0.0.3";

// Global error handler
const errorHandler = (error: Error, info: ErrorInfo): void => {
  // You can log errors to third-party services
  if (import.meta.env.DEV) {
    console.error(error, info);
  }
};

// Create app
const root = createRoot(document.getElementById("root")!);

// Render the app
root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
