import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import AuthLayout from "@/layouts/AuthLayout";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import { useFeatureFlags, useIsFeatureEnabled } from "@precedent/shared-util";
import { lazy } from "react";
import { Navigate, type RouteObject } from "react-router-dom";

// Lazy load components
const SettingsPage = lazy(() => import("@/pages/Settings"));
const FirmSettingsPage = lazy(() => import("@/pages/FirmSettings"));
const ChatPage = lazy(() => import("@/pages/Chat"));
const DocumentUpload = lazy(() => import("@/pages/DocumentUpload"));
const DocumentPreview = lazy(() => import("@/pages/DocumentPreview"));
const Page404 = lazy(() => import("@/pages/Page404"));
const DirectorySubmissions = lazy(() => import("@/pages/DirectorySubmissions"));
const SubmissionDetail = lazy(
  () => import("@/pages/DirectorySubmissions/SubmissionDetail"),
);
const Questionnaires = lazy(() => import("@/pages/Questionnaires"));
const QuestionnaireDetail = lazy(
  () => import("@/pages/Questionnaires/QuestionnaireDetail"),
);
const Urls = lazy(() => import("@/pages/Urls"));
const Recovery = lazy(() => import("@/pages/auth/Recovery"));
const Reset = lazy(() => import("@/pages/auth/Reset"));
const MagicLink = lazy(() => import("@/pages/auth/MagicLink"));
const SignIn = lazy(() => import("@/pages/auth/SignIn"));
const SignUp = lazy(() => import("@/pages/auth/SignUp"));
const SignInWithMagicLink = lazy(
  () => import("@/pages/auth/SignInWithMagicLink"),
);

const withFeatureProtection = (
  WrappedComponent: React.ComponentType<any>,
  featureFlag: string,
): React.ReactElement => {
  const FeatureProtectedComponent: React.FC = (props) => {
    const isFeatureEnabled = useIsFeatureEnabled(featureFlag);
    return isFeatureEnabled ? <WrappedComponent {...props} /> : <Page404 />;
  };
  return <FeatureProtectedComponent />;
};

const RootRedirect = () => {
  const chatEnabled = useIsFeatureEnabled("chat-module");
  const questionnairesEnabled = useIsFeatureEnabled("questionnaires-module");
  const directorySubmissionsEnabled = useIsFeatureEnabled(
    "directory-submissions-module",
  );

  const { userId: authUserId } = useAuth();
  const { isLoading, userId: featureFlagUserId } = useFeatureFlags();

  // Check if feature flags are still loading
  if (
    isLoading ||
    chatEnabled === null ||
    questionnairesEnabled === null ||
    directorySubmissionsEnabled === null ||
    authUserId !== featureFlagUserId
  ) {
    return <Spinner />;
  }

  // Proceed with routing decision based on loaded feature flags
  if (chatEnabled) {
    return <Navigate to="/chat" replace />;
  }

  if (questionnairesEnabled) {
    return <Navigate to="/questionnaires" replace />;
  }

  if (directorySubmissionsEnabled) {
    return <Navigate to="/directory-submissions" replace />;
  }

  return <Navigate to="/firm-settings" replace />;
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: <RootRedirect />,
      },
      {
        path: "chat",
        children: [
          {
            index: true,
            element: withFeatureProtection(ChatPage, "chat-module"),
          },
          {
            path: ":chatId",
            element: withFeatureProtection(ChatPage, "chat-module"),
          },
        ],
      },
      {
        path: "urls",
        element: <Urls />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "firm-settings",
        element: <FirmSettingsPage />,
      },
      {
        path: "questionnaires",
        children: [
          {
            index: true,
            element: withFeatureProtection(
              Questionnaires,
              "questionnaires-module",
            ),
          },
          {
            path: ":documentId",
            element: withFeatureProtection(
              QuestionnaireDetail,
              "questionnaires-module",
            ),
          },
        ],
      },
      {
        path: "documents",
        children: [
          { index: true, element: <DocumentUpload /> },
          { path: ":embeddingId", element: <DocumentPreview /> },
        ],
      },
      {
        path: "directory-submissions",
        children: [
          {
            index: true,
            element: withFeatureProtection(
              DirectorySubmissions,
              "directory-submissions-module",
            ),
          },
          {
            path: ":submissionId",
            element: withFeatureProtection(
              SubmissionDetail,
              "directory-submissions-module",
            ),
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { path: "sign-in", element: <SignIn /> },
      { path: "sign-up", element: <SignUp /> },
      { path: "recovery", element: <Recovery /> },
      { path: "set-password", element: <Reset /> },
      { path: "sign-in-with-magic-link", element: <SignInWithMagicLink /> },
      { path: "magic-link", element: <MagicLink /> },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
