import SuspenseOutlet from "@/components/SuspenseOutlet";
import { appVersionAtom } from "@/supabase";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useState } from "react";

const AuthLayout = () => {
  const appVersion = useAtomValue(appVersionAtom);
  const [showVersion, setShowVersion] = useState(false);

  return (
    <div>
      <SuspenseOutlet />
      <div
        className={classNames(
          "ml-4 cursor-default text-gray-600 opacity-0",
          "transition-opacity duration-100 ease-in",
          "text-center text-sm",
          showVersion ? "opacity-100" : "opacity-0",
        )}
        onClick={() => {
          setShowVersion((x) => !x);
        }}
      >
        {appVersion}
      </div>
    </div>
  );
};

export default AuthLayout;
