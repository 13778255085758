import { queryKeys } from "@/client";
import {
  type DirectorySubmissionFull,
  type SubmissionFormData,
} from "@/components/DirectorySubmissions/Form/types";
import {
  loadSubmissionData,
  saveData,
  submissionToFormData,
} from "@/components/DirectorySubmissions/Form/utils";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import type React from "react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface SubmissionsContextValue {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  submissionData: DirectorySubmissionFull | null;
  formData: SubmissionFormData;
  setFormData: React.Dispatch<React.SetStateAction<SubmissionFormData>>;
  initialFormData: SubmissionFormData | null;
  error: boolean;
}

const SubmissionsContext = createContext<SubmissionsContextValue | null>(null);

export const SubmissionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { submissionId } = useParams();
  const { supabase, firmId, userId } = useAuth();
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const initialFormDataRef = useRef<SubmissionFormData | null>(null);
  const [formData, setFormData] = useState<SubmissionFormData>({});
  const previousSubmissionIdRef = useRef<string | undefined>(undefined);

  const savingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    data: submissionData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: queryKeys.directorySubmission(submissionId ?? ""),
    queryFn: async (): Promise<DirectorySubmissionFull> => {
      if (!submissionId) {
        throw new Error("No submissionId");
      }
      try {
        const data = await loadSubmissionData(
          submissionId,
          supabase as SupabaseClient,
        );
        return data;
      } catch (err) {
        setError(true);
        throw err;
      }
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  useEffect(() => {
    setIsSaving(isRefetching);
  }, [isRefetching]);

  // Reset formData and initialFormDataRef when submissionId changes to undefined
  useEffect(() => {
    if (submissionId === undefined) {
      setFormData({});
      initialFormDataRef.current = null;
    }
  }, [submissionId]);

  // Update formData and initialFormDataRef when submissionData changes
  useEffect(() => {
    if (submissionData) {
      const newInitialFormData = submissionToFormData(submissionData);
      setFormData(newInitialFormData);
      initialFormDataRef.current = newInitialFormData;
    }
  }, [submissionData]);

  // Save all unsaved changes when submissionId changes
  useEffect(() => {
    const saveAllChanges = async () => {
      if (
        previousSubmissionIdRef.current &&
        previousSubmissionIdRef.current !== submissionId &&
        initialFormDataRef.current
      ) {
        const changedFields = Object.keys(formData).filter(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

        if (changedFields.length > 0) {
          setIsSaving(true);
          try {
            await saveData({
              supabase,
              firmId,
              userId,
              submissionId: parseInt(previousSubmissionIdRef.current),
              enqueueSnackbar,
              formData,
              initialFormData: initialFormDataRef.current,
              changedFields,
            });
            enqueueSnackbar("Changes saved successfully", {
              variant: "success",
            });
          } catch (saveError) {
            enqueueSnackbar("Failed to save changes", { variant: "error" });
          } finally {
            setIsSaving(false);
          }

          // Invalidate the cache to ensure fresh data is fetched next time
          void queryClient.invalidateQueries({
            queryKey: queryKeys.directorySubmission(
              previousSubmissionIdRef.current ?? "",
            ),
          });
        }
      }
      previousSubmissionIdRef.current = submissionId;
    };

    void saveAllChanges();
  }, [
    submissionId,
    supabase,
    firmId,
    userId,
    enqueueSnackbar,
    formData,
    queryClient,
  ]);

  // Add beforeunload event listener to warn about unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const hasUnsavedChanges =
        initialFormDataRef.current &&
        Object.keys(formData).some(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

      if (hasUnsavedChanges) {
        // Modern browsers no longer support setting a custom message
        e.preventDefault();
        e.returnValue = ""; // This shows the default browser confirmation message
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formData]);

  // Effect to handle auto-reset of isSaving after 10 seconds
  useEffect(() => {
    if (isSaving) {
      // Clear any existing timer
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }

      savingTimeoutRef.current = setTimeout(() => {
        if (isSaving) {
          setIsSaving(false);
        }
      }, 10000);
    } else {
      // If not saving, clear any existing timer
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
        savingTimeoutRef.current = null;
      }
    }

    // Cleanup on unmount
    return () => {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }
    };
  }, [isSaving, enqueueSnackbar]);

  // Render logic:
  // - Always provide the context
  // - If submissionId is present and data is loading, show Spinner
  // - Otherwise, render children

  return (
    <SubmissionsContext.Provider
      value={{
        isSaving,
        setIsSaving,
        submissionData: submissionData ?? null,
        formData,
        setFormData,
        initialFormData: initialFormDataRef.current,
        error,
      }}
    >
      {submissionId && (isLoading || !submissionData) ? <Spinner /> : children}
    </SubmissionsContext.Provider>
  );
};

export const useSubmissions = () => {
  const context = useContext(SubmissionsContext);
  if (!context) {
    throw new Error("useSubmissions must be used within a SubmissionsProvider");
  }
  return context;
};
