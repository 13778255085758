import type { User } from "@precedent/db-types/src/schema";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { atom } from "jotai";

export const supabaseUrlAtom = atom(undefined as string | undefined);
export const supabaseClientAtom = atom(undefined as SupabaseClient | undefined);
export const supabaseSessionAtom = atom(
  "loading" as SupabaseSession | null | "loading",
);
export const supabaseUserAtom = atom(undefined as User | undefined);
export const firmIdAtom = atom(undefined as string | undefined);
export const firmNameAtom = atom("loading" as string | null | "loading");
export const appVersionAtom = atom(undefined as string | undefined);
export const uploadsInFlightAtom = atom(new Set<string>());

export const getSiteUrl = () => {
  let url = (() => {
    if (import.meta.env.VITE_PUBLIC_SITE_URL) {
      return import.meta.env.VITE_PUBLIC_SITE_URL;
    } else {
      return "http://localhost:5173/";
    }
  })();

  url = url.startsWith("http") ? url : `https://${url}`;
  url = url.endsWith("/") ? url : `${url}/`;
  return url;
};
