import Spinner from "@/components/Spinner";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const SuspenseOutlet = () => (
  <Suspense fallback={<Spinner />}>
    <Outlet />
  </Suspense>
);

export default SuspenseOutlet;
