import {
  type DirectorySubmissionFull,
  type SubmissionFormData,
} from "@/components/DirectorySubmissions/Form/types";
import { defaultArrays } from "@/components/DirectorySubmissions/Form/utils";

export const submissionToFormData = (
  submission: DirectorySubmissionFull,
): SubmissionFormData => {
  const data: SubmissionFormData = {};
  // Populate overview data
  data.A1 = submission.firm_name || "";
  data.A2 = submission.practice_area_name || "";
  data.A3 = submission.location || "";
  data.B1 = submission.department_name || "";
  data.B4 = submission.ethnic_minority_percentage || "";
  data.B5 = submission.lgbt_percentage || "";
  data.B6 = submission.disability_percentage || "";
  data.B10 = submission.department_specialties || "";
  data.C2 = submission.coverage_feedback || "";

  data.A4 =
    submission.contactPersons?.length > 0
      ? submission.contactPersons
          .sort((a, b) => a.id - b.id)
          .map((cp) => ({
            id: cp.id,
            value: [cp.name ?? "", cp.email ?? "", cp.telephone_number ?? ""],
          }))
      : defaultArrays.A4;

  data.B2 = [
    {
      id: 0,
      value: [
        submission.partners_count ?? "",
        submission.partners_male_female_ratio ?? "",
      ],
    },
  ];

  data.B3 = [
    {
      id: 0,
      value: [
        submission.other_lawyers_count ?? "",
        submission.other_lawyers_male_female_ratio ?? "",
      ],
    },
  ];

  data.B7 =
    submission.departmentHeads?.length > 0
      ? submission.departmentHeads
          .sort((a, b) => a.id - b.id)
          .map((dh) => ({
            id: dh.id,
            value: [dh.name ?? "", dh.email ?? "", dh.telephone_number ?? ""],
          }))
      : defaultArrays.B7;
  data.B8 =
    submission.partnerChanges?.length > 0
      ? submission.partnerChanges
          .sort((a, b) => a.id - b.id)
          .map((pc) => ({
            id: pc.id,
            value: [
              pc.name ?? "",
              pc.joined_departed ?? "",
              pc.joined_from_destination ?? "",
            ],
          }))
      : defaultArrays.B8;
  data.B9 =
    submission.lawyerInfo?.length > 0
      ? submission.lawyerInfo
          .sort((a, b) => a.id - b.id)
          .map((li) => ({
            id: li.id,
            value: [
              li.name ?? "",
              li.comments_or_web_link ?? "",
              li.is_partner ?? null,
              li.is_ranked ?? null,
              li.additional_info ?? "",
            ],
          }))
      : defaultArrays.B9;
  data.C1 =
    submission.barristersUsed?.length > 0
      ? submission.barristersUsed
          .sort((a, b) => a.id - b.id)
          .map((bu) => ({
            id: bu.id,
            value: [bu.name ?? "", bu.firm_or_set ?? "", bu.comments ?? ""],
          }))
      : defaultArrays.C1;
  data.PC =
    submission.publishableClients?.length > 0
      ? submission.publishableClients
          .sort((a, b) => a.id - b.id)
          .map((pc) => ({
            id: pc.id,
            value: [pc.client_name ?? "", pc.is_new_client ?? null],
          }))
      : defaultArrays.PC;
  data.CC =
    submission.confidentialClients?.length > 0
      ? submission.confidentialClients
          .sort((a, b) => a.id - b.id)
          .map((cc) => ({
            id: cc.id,
            value: [cc.client_name ?? "", cc.is_new_client ?? null],
          }))
      : defaultArrays.CC;

  // Populate matter data
  const populateMatters = (
    matters: typeof submission.matters,
    prefix: string,
  ) => {
    matters.forEach((matter, index) => {
      const key = `${prefix}${index + 1}`;
      data[key] = {
        0: matter.client_name ?? "",
        1: matter.matter_description ?? "",
        2: matter.value?.toString() || "",
        3: matter.cross_border ?? "",
        4: matter.lead_partner_name ?? "",
        5: matter.other_team_members ?? "",
        6: matter.other_firms ?? "",
        7: matter.date_of_completion?.toString() || "",
        8: matter.other_info ?? "",
        id: matter.id.toString(),
      };
    });
  };

  populateMatters(
    submission.matters
      .filter((matter) => matter.is_public)
      .sort((a, b) => (a.included_order ?? 0) - (b.included_order ?? 0)),
    "PM",
  );
  populateMatters(
    submission.matters
      .filter((matter) => !matter.is_public)
      .sort((a, b) => (a.included_order ?? 0) - (b.included_order ?? 0)),
    "CM",
  );

  return data;
};
