import classNames from "classnames";
import React from "react";

const NoFirm = () => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  return (
    <div
      className={classNames(
        "hero min-h-screen",
        loaded
          ? "bg-gray-800 text-gray-200 transition-colors duration-[5000ms]"
          : "bg-white text-white",
      )}
    >
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">
            Please wait for firm assignment
          </h1>
          <p className="my-10">
            Your account will be assigned to your firm’s Briefly organization
            after verification by your administrator. If you have any questions,
            please contact{" "}
            <a href="mailto:support@briefly.legal">support@briefly.legal</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoFirm;
