import { Session } from "@supabase/supabase-js";
import React, { useState, useEffect, useContext } from "react";

// Define the type for FeatureFlag data
export interface FeatureFlagData {
  isEnabled: boolean;
  trialEndDate: Date | null;
}

// Define the context value type
interface FeatureFlagsContextValue {
  featureFlags: Map<string, FeatureFlagData>;
  isLoading: boolean;
  firmId: string | null | undefined;
  userId: string | undefined;
}

// Create the FeatureFlagsContext
const FeatureFlagsContext =
  React.createContext<FeatureFlagsContextValue | null>(null);

// Custom hook to use the feature flags context
export const useFeatureFlags = (): FeatureFlagsContextValue => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    return {
      featureFlags: new Map(),
      isLoading: true,
      userId: undefined,
      firmId: null,
    };
  }
  return context;
};

// Hook to check if a feature is enabled
export const useIsFeatureEnabled = (featureName: string): boolean | null => {
  const { featureFlags, isLoading } = useFeatureFlags();

  if (isLoading) {
    // During loading, return null to indicate that the feature flags are not yet loaded
    return null;
  }

  const featureFlag = featureFlags.get(featureName);
  return featureFlag ? featureFlag.isEnabled : false;
};

// FeatureFlagProvider props
interface FeatureFlagProviderProps {
  service: FeatureFlagService | null;
  children: React.ReactNode;
}

// The FeatureFlagProvider component
export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  service,
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState(
    new Map<string, FeatureFlagData>(),
  );
  const [isLoading, setIsLoading] = useState(
    service ? service.getIsLoading() : true,
  );

  useEffect(() => {
    if (!service) {
      return;
    }

    // Subscribe to feature flag changes
    const unsubscribeFeatureFlags = service.subscribeToFeatureFlagChanges(
      (updatedFeatureFlags) => {
        setFeatureFlags(new Map(updatedFeatureFlags));
      },
    );

    // Subscribe to isLoading changes
    const unsubscribeIsLoading =
      service.subscribeToIsLoadingChanges(setIsLoading);

    // Cleanup on unmount
    return () => {
      unsubscribeFeatureFlags();
      unsubscribeIsLoading();
    };
  }, [service]);

  if (isLoading || !service) {
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-800">
        <span className="loading loading-spinner h-10 w-10" />
      </div>
    );
  }

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        isLoading,
        firmId: service.getFirmId(),
        userId: service.getUserId(),
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

// FeatureFlagService interface
export interface FeatureFlagService {
  loadInitialFeatureFlags: () => Promise<void>;
  getAllFeatureFlags: () => Map<string, FeatureFlagData>;
  subscribeToFeatureFlagChanges: (
    callback: (featureFlags: Map<string, FeatureFlagData>) => void,
  ) => () => void;
  subscribeToIsLoadingChanges(
    callback: (newState: boolean) => void,
  ): () => void;
  getIsLoading(): FeatureFlagsContextValue["isLoading"];
  getUserId(): FeatureFlagsContextValue["userId"];
  getFirmId(): FeatureFlagsContextValue["firmId"];
}

// BaseFeatureFlagService class with getIdentifier method
export abstract class BaseFeatureFlagService implements FeatureFlagService {
  protected userId?: string;
  protected firmId?: string | null;

  constructor(config: { userId?: string; firmId?: string | null }) {
    this.userId = config.userId;
    this.firmId = config.firmId;
  }

  // Reintroduce the getIdentifier method
  protected getIdentifier(options?: { userId?: string; firmId?: string }) {
    return {
      userId: options?.userId ?? this.userId,
      firmId: options?.firmId ?? this.firmId,
    };
  }

  abstract loadInitialFeatureFlags(): Promise<void>;
  abstract getAllFeatureFlags(): Map<string, FeatureFlagData>;
  abstract subscribeToFeatureFlagChanges(
    callback: (featureFlags: Map<string, FeatureFlagData>) => void,
  ): () => void;
  abstract subscribeToIsLoadingChanges(
    callback: (newState: boolean) => void,
  ): () => void;
  abstract getIsLoading(): FeatureFlagsContextValue["isLoading"];
  abstract getUserId(): FeatureFlagsContextValue["userId"];
  abstract getFirmId(): FeatureFlagsContextValue["firmId"];
}
