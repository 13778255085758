import { userDisplayName, userFullName } from "@/util/strings";
import { icons, initials } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import type { User } from "@precedent/db-types/src/schema";
import { twMerge } from "tailwind-merge";

import SecureAvatar from "./SecureAvatar";

const InitialsAvatar = ({
  user,
  size = 32,
  showTooltip = true,
  tooltipDirection = "left",
  onClick,
  className,
}: {
  user?: Pick<User, "first_name" | "last_name" | "email" | "avatar_url">;
  size?: number;
  showTooltip?: boolean;
  tooltipDirection?: string;
  onClick?: (e: any) => void;
  className?: string;
}) => {
  const getAvatar = () => {
    if (!user?.email || !user) {
      return (
        <div
          className={twMerge(
            `h-[${size}px] w-[${size}px] rounded-full bg-white`,
            className,
            onClick ? "cursor-pointer" : "",
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={{ height: `${size}px`, width: `${size}px` }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>
        </div>
      );
    }

    const fullName = userFullName(user);

    if (user.avatar_url) {
      return (
        <div
          className={twMerge(
            `overflow-hidden rounded-full`,
            className,
            onClick ? "cursor-pointer" : "",
          )}
          style={{ width: `${size}px`, height: `${size}px` }}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          <SecureAvatar
            filePath={user.avatar_url}
            alt="Profile"
            size={size}
            className="h-full w-full object-cover"
          />
        </div>
      );
    }

    if (fullName) {
      const initialsAvatar = createAvatar(initials, {
        seed: fullName,
        radius: 50,
        size,
      }).toDataUriSync();

      return (
        <div
          className={twMerge(
            `rounded-full`,
            className,
            onClick ? "cursor-pointer" : "",
          )}
          style={{ width: `${size}px`, height: `${size}px` }}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          <img src={initialsAvatar} loading="lazy" alt="Profile" />
        </div>
      );
    }

    if (user?.email) {
      const emailAvatar = createAvatar(icons, {
        seed: user.email,
        radius: 50,
        size,
      }).toDataUriSync();

      return (
        <div
          className={twMerge(
            `rounded-full`,
            className,
            onClick ? "cursor-pointer" : "",
          )}
          style={{ width: `${size}px`, height: `${size}px` }}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          <img src={emailAvatar} loading="lazy" alt="Profile" />
        </div>
      );
    }
  };

  return showTooltip ? (
    <div
      className={`tooltip tooltip-${tooltipDirection}`}
      data-tip={user ? userDisplayName(user) : "Unassigned"}
    >
      {getAvatar()}
    </div>
  ) : (
    getAvatar()
  );
};

export default InitialsAvatar;
