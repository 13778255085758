import capitalize from "lodash/capitalize";

export function userInitials(user: {
  first_name: string | null;
  last_name: string | null;
}) {
  return user
    ? `${user.first_name?.charAt(0) ?? ""}${user.last_name?.charAt(0) ?? ""}`
    : "";
}

export function userFullName(user: {
  first_name: string | null;
  last_name: string | null;
}) {
  return user
    ? `${user.first_name ?? ""}${user.last_name ? " " + user.last_name : ""}`
    : "";
}

export function userDisplayName(user: {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
}) {
  return user ? userFullName(user) || user.email : "";
}

export const parseStatusString = (s: string | null): string => {
  switch (s) {
    case "IN_PROGRESS":
      return "In Progress";
    case "TO_DO":
      return "To-do";
    case "NOT_PROCESSED":
      return "Not Processed";
    case null:
      return "";
    default:
      return capitalize(s);
  }
};

export const sortBySubsection = (
  a: { subsection_index: string | null },
  b: { subsection_index: string | null },
) => {
  const parseIndex = (index: string) => {
    // Split the index by '.' and then map each part
    return index.split(".").map((part) => {
      // Check if the part is numeric
      if (!isNaN(Number(part))) {
        // Return the number
        return { type: "number", value: Number(part) };
      } else {
        // Return the string
        return { type: "string", value: part };
      }
    });
  };

  const numA = a.subsection_index ? parseIndex(a.subsection_index) : [];
  const numB = b.subsection_index ? parseIndex(b.subsection_index) : [];

  const maxLength = Math.max(numA.length, numB.length);

  for (let i = 0; i < maxLength; i++) {
    const partA = numA[i] || { type: "number", value: 0 }; // Treat missing subsection as 0
    const partB = numB[i] || { type: "number", value: 0 };

    if (partA.type === partB.type) {
      // Both parts are of the same type (number or string)
      if (partA.value !== partB.value) {
        return partA.value < partB.value ? -1 : 1;
      }
    } else {
      // One part is a number, the other is a string
      // We consider numbers to come before strings
      return partA.type === "number" ? -1 : 1;
    }
  }

  // If subsections are the same or missing, don't sort
  return 0;
};
