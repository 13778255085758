import { type TableRow } from "@/components/DirectorySubmissions/Form/types";
import { type SupabaseClient } from "@supabase/supabase-js";

type FieldMapping = Record<string, string[]>;

const fieldMappings: FieldMapping = {
  directory_submission_contact_persons: ["name", "email", "telephone_number"],
  directory_submission_department_heads: ["name", "email", "telephone_number"],
  directory_submission_partner_changes: [
    "name",
    "joined_departed",
    "joined_from_destination",
  ],
  directory_submission_lawyer_info: [
    "name",
    "comments_or_web_link",
    "is_partner",
    "is_ranked",
    "additional_info",
  ],
  directory_submission_barristers_used: ["name", "firm_or_set", "comments"],
  directory_submission_publishable_clients: ["client_name", "is_new_client"],
  directory_submission_confidential_clients: ["client_name", "is_new_client"],
};

const transformData = (
  data: Array<Array<string | boolean | null>>,
  fields: string[],
): Array<Record<string, any>> => {
  return data.map((row) =>
    fields.reduce<Record<string, any>>((obj, field, index) => {
      obj[field] = row[index];
      if (
        field === "is_partner" ||
        field === "is_ranked" ||
        field === "is_new_client"
      ) {
        obj[field] = row[index] === null ? null : Boolean(row[index]);
      }
      return obj;
    }, {}),
  );
};

export const saveTableData = async (
  tableName: string,
  submissionId: number,
  data: TableRow[],
  firmId: string,
  supabase: SupabaseClient,
): Promise<TableRow[]> => {
  try {
    const transformedData = data.map((entry) => ({
      ...transformData([entry.value], fieldMappings[tableName])[0],
      ...(entry.id && { id: entry.id }),
      firm_id: firmId,
      directory_submission_id: submissionId,
    }));

    if (transformedData.length > 0) {
      const { data: upsertedData, error: upsertError } = await supabase
        .from(tableName)
        .upsert(transformedData, {
          onConflict: "id",
          ignoreDuplicates: false,
        })
        .select();

      if (upsertError) {
        console.error(`Error upserting rows in ${tableName}:`, upsertError);
        throw new Error(
          `Error upserting rows in ${tableName}: ${upsertError.message}`,
        );
      }

      return upsertedData.map((item) => ({
        id: item.id,
        value: fieldMappings[tableName].map((field) => item[field] || ""),
      }));
    }
    return data;
  } catch (error) {
    console.error(`Error updating related table ${tableName}:`, error);
    throw new Error(`Error updating related table ${tableName}: ${error}`);
  }
};
