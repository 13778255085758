import {
  firmIdAtom,
  firmNameAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
  supabaseUserAtom,
} from "@/supabase";
import { type User } from "@precedent/db-types/src/schema";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { useAtomValue } from "jotai";

type AuthContext = {
  session: null | "loading" | SupabaseSession;
  supabase: undefined | SupabaseClient;
  firmId: undefined | string;
  userId: undefined | string;
  user: undefined | User;
  firmName: null | "loading" | string;
};

export function useAuth(): AuthContext {
  const session = useAtomValue(supabaseSessionAtom);
  const supabase = useAtomValue(supabaseClientAtom);
  const supabaseUser = useAtomValue(supabaseUserAtom);
  const firmId = useAtomValue(firmIdAtom);
  const firmName = useAtomValue(firmNameAtom);

  const userId = (() => {
    if (session !== "loading" && session !== null) {
      return session.user.id;
    }
  })();

  return {
    session,
    supabase,
    firmId,
    userId,
    user: supabaseUser,
    firmName,
  };
}
