import { useAuth } from "@/contexts/AuthHook";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";

const signedUrlCache = new Map();

const SecureAvatar = ({
  filePath,
  alt,
  className,
  size = 32,
}: {
  filePath: string;
  alt: string;
  className?: string;
  size?: number;
}) => {
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const { supabase } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const getSignedUrl = async () => {
      // Check if we have a cached signed URL that hasn't expired
      const cacheEntry = signedUrlCache.get(filePath);
      const now = Date.now();

      if (cacheEntry && cacheEntry.expiration > now) {
        // Use the cached signed URL
        setSignedUrl(cacheEntry.url);
      } else {
        // Generate a new signed URL with image transformations
        try {
          const { data, error } = await (supabase as SupabaseClient).storage
            .from("uploads")
            .createSignedUrl(
              filePath,
              60 * 60, // 1 hour expiration in seconds
              {
                transform: {
                  width: 100,
                  height: 100,
                },
              },
            );

          if (error) {
            console.error("Error creating signed URL:", error);
            return;
          }

          if (data?.signedUrl) {
            if (isMounted) {
              setSignedUrl(data.signedUrl);
            }
            // Cache the signed URL with its expiration time
            signedUrlCache.set(filePath, {
              url: data.signedUrl,
              expiration: now + 60 * 60 * 1000, // Current time plus 1 hour in milliseconds
            });
          }
        } catch (error) {
          console.error("Error creating signed URL:", error);
        }
      }
    };

    void getSignedUrl();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [filePath, supabase]);

  if (!signedUrl) {
    return null; // Or a placeholder image
  }

  return (
    <img
      src={signedUrl}
      alt={alt}
      className={className}
      style={{ width: `${size}px`, height: `${size}px`, objectFit: "cover" }}
    />
  );
};

export default SecureAvatar;
