import { SubmissionsProvider } from "@/components/DirectorySubmissions/Form/SubmissionsProvider";
import Spinner from "@/components/Spinner";
import MainLayout from "@/layouts/MainLayout";
import { supabaseSessionAtom } from "@/supabase";
import { useAtomValue } from "jotai";
import { Navigate } from "react-router-dom";

const ProtectedLayout = () => {
  const session = useAtomValue(supabaseSessionAtom);

  if (session === "loading") {
    return <Spinner />;
  }

  if (!session) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  return (
    <SubmissionsProvider>
      <MainLayout />
    </SubmissionsProvider>
  );
};

export default ProtectedLayout;
