import { twMerge } from "tailwind-merge";

const Spinner = ({ className }: { className?: string }) => (
  <div
    className={twMerge(
      "flex h-screen w-full items-center justify-center text-gray-800",
      className,
    )}
  >
    <span className="loading loading-spinner h-10 w-10" />
  </div>
);

export default Spinner;
