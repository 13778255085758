import { queryClient, queryKeys } from "@/client";
import {
  type SubmissionFormData,
  type TableRow,
} from "@/components/DirectorySubmissions/Form/types";
import {
  saveMatterData,
  saveTableData,
} from "@/components/DirectorySubmissions/Form/utils";
import { type SupabaseClient } from "@supabase/supabase-js";
import { type EnqueueSnackbar } from "notistack";

export const saveData = async ({
  supabase,
  firmId,
  userId,
  submissionId,
  enqueueSnackbar,
  formData,
  initialFormData,
  changedFields = [],
  invalidFieldCount,
}: {
  supabase: SupabaseClient | undefined;
  firmId: string | undefined;
  userId: string | undefined;
  submissionId: number | undefined;
  enqueueSnackbar: EnqueueSnackbar;
  formData: SubmissionFormData;
  initialFormData: SubmissionFormData;
  changedFields?: string[];
  invalidFieldCount?: number;
}): Promise<Partial<SubmissionFormData>> => {
  if (!submissionId || !firmId || !userId || !supabase) {
    enqueueSnackbar("Failed to save changes, please refresh and try again.", {
      variant: "error",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    return {};
  }

  const updatedData: Partial<SubmissionFormData> = {};

  try {
    // Only update invalid_field_count if it's provided
    if (invalidFieldCount !== undefined) {
      await supabase
        .from("directory_submission")
        .update({ invalid_field_count: invalidFieldCount })
        .eq("id", submissionId);
    }

    const overviewFields = {
      A1: "firm_name",
      A2: "practice_area",
      A3: "location",
      B1: "department_name",
      B4: "ethnic_minority_percentage",
      B5: "lgbt_percentage",
      B6: "disability_percentage",
      B10: "department_specialties",
      C2: "coverage_feedback",
    };

    for (const [key, dbField] of Object.entries(overviewFields)) {
      if (
        changedFields.includes(key) &&
        formData[key] !== initialFormData[key]
      ) {
        await supabase.from("directory_submission_overview").upsert(
          {
            [dbField]: formData[key],
            firm_id: firmId,
            directory_submission_id: submissionId,
          },
          {
            onConflict: "directory_submission_id",
          },
        );
        updatedData[key] = formData[key];
      }
    }

    // Handle B2 and B3 separately
    if (changedFields.includes("B2")) {
      const [partnersCount, partnersMaleFemaleRatio] = (
        formData.B2 as TableRow[]
      )[0].value;
      await supabase.from("directory_submission_overview").upsert(
        {
          partners_count: partnersCount,
          partners_male_female_ratio: partnersMaleFemaleRatio,
          firm_id: firmId,
          directory_submission_id: submissionId,
        },
        {
          onConflict: "directory_submission_id",
        },
      );
      updatedData.B2 = formData.B2;
    }

    if (changedFields.includes("B3")) {
      const [otherLawyersCount, otherLawyersMaleFemaleRatio] = (
        formData.B3 as TableRow[]
      )[0].value;
      await supabase.from("directory_submission_overview").upsert(
        {
          other_lawyers_count: otherLawyersCount,
          other_lawyers_male_female_ratio: otherLawyersMaleFemaleRatio,
          firm_id: firmId,
          directory_submission_id: submissionId,
        },
        {
          onConflict: "directory_submission_id",
        },
      );
      updatedData.B3 = formData.B3;
    }

    // Update related tables only if they have changed
    const relatedTables = [
      { key: "A4", table: "directory_submission_contact_persons" },
      { key: "B7", table: "directory_submission_department_heads" },
      { key: "B8", table: "directory_submission_partner_changes" },
      { key: "B9", table: "directory_submission_lawyer_info" },
      { key: "C1", table: "directory_submission_barristers_used" },
      { key: "PC", table: "directory_submission_publishable_clients" },
      { key: "CC", table: "directory_submission_confidential_clients" },
    ];

    for (const { key, table } of relatedTables) {
      if (
        changedFields.includes(key) &&
        JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])
      ) {
        const updatedTableData = await saveTableData(
          table,
          submissionId,
          formData[key] as TableRow[],
          firmId,
          supabase,
        );
        updatedData[key] = updatedTableData;
      }
    }

    // Handle matters (both public and confidential)
    const changedPMMatters: Record<string, Record<string, any>> = {};
    const changedCMMatters: Record<string, Record<string, any>> = {};

    changedFields.forEach((field) => {
      if (field.startsWith("PM") && field !== "PC") {
        changedPMMatters[field] = formData[field] as Record<string, any>;
      } else if (field.startsWith("CM") && field !== "CC") {
        changedCMMatters[field] = formData[field] as Record<string, any>;
      }
    });

    if (Object.keys(changedPMMatters).length > 0) {
      const updatedPMMatters = await saveMatterData(
        "PM",
        submissionId,
        changedPMMatters,
        firmId,
        userId,
        supabase,
      );
      Object.assign(updatedData, updatedPMMatters);
    }
    if (Object.keys(changedCMMatters).length > 0) {
      const updatedCMMatters = await saveMatterData(
        "CM",
        submissionId,
        changedCMMatters,
        firmId,
        userId,
        supabase,
      );
      Object.assign(updatedData, updatedCMMatters);
    }

    void queryClient.invalidateQueries({
      queryKey: queryKeys.directorySubmission(`${submissionId}`),
    });

    return updatedData;
  } catch (error) {
    console.error("Error saving data:", error);
    enqueueSnackbar("Failed to save changes, please refresh and try again.", {
      variant: "error",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });

    return {};
  }
};
