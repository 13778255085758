import {
  type DirectorySubmissionFull,
  type DirectorySubmissionFullReturn,
} from "@/components/DirectorySubmissions/Form/types";
import { type SupabaseClient } from "@supabase/supabase-js";

export async function loadSubmissionData(
  submissionId: string,
  supabase: SupabaseClient,
): Promise<DirectorySubmissionFull> {
  // Fetch data from Supabase
  const { data, error } = await supabase
    .from("directory_submission")
    .select(
      `
      id,
      status,
      assignee,
      submission_status,
      firm_id,
      directory_schedule_id (
        year,
        current_research_status,
        research_status_date,
        imported_at,
        researcher_notes,
        directory_canonical_practice_area_id (
          canonicalId: id,
          practice_area_name,
          location,
          guide
        )
      ),
      directory_submission_overview!directory_submission_id (
        directory_submission_overview_id: id,
        firm_name,
        department_name,
        partners_count,
        partners_male_female_ratio,
        other_lawyers_count,
        other_lawyers_male_female_ratio,
        ethnic_minority_percentage,
        lgbt_percentage,
        disability_percentage,
        department_specialties,
        coverage_feedback
      ),
      directory_submission_contact_persons!directory_submission_id (
        id,
        name,
        email,
        telephone_number
      ),
      directory_submission_department_heads!directory_submission_id (
        id,
        name,
        email,
        telephone_number
      ),
      directory_submission_partner_changes!directory_submission_id (
        id,
        name,
        joined_departed,
        joined_from_destination
      ),
      directory_submission_lawyer_info!directory_submission_id (
        id,
        name,
        comments_or_web_link,
        is_partner,
        is_ranked,
        additional_info
      ),
      directory_submission_barristers_used!directory_submission_id (
        id,
        name,
        firm_or_set,
        comments
      ),
      directory_submission_publishable_clients!directory_submission_id (
        id,
        client_name,
        is_new_client
      ),
      directory_submission_confidential_clients!directory_submission_id (
        id,
        client_name,
        is_new_client
      ),
      directory_matter!directory_submission_id (*)
    `,
    )
    .eq("id", submissionId)
    .returns<DirectorySubmissionFullReturn[]>()
    .single();

  if (error) {
    // Handle error appropriately
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("No data returned from query");
  }

  // Flatten the structure
  try {
    const flattened: Partial<DirectorySubmissionFull> = {
      ...data,
      ...(data.directory_schedule_id || {}),
      ...(data.directory_schedule_id?.directory_canonical_practice_area_id ||
        {}),
      ...(data.directory_submission_overview || {}),
    };

    flattened.contactPersons = data.directory_submission_contact_persons || [];
    flattened.departmentHeads =
      data.directory_submission_department_heads || [];
    flattened.partnerChanges = data.directory_submission_partner_changes || [];
    flattened.lawyerInfo = data.directory_submission_lawyer_info || [];
    flattened.barristersUsed = data.directory_submission_barristers_used || [];
    flattened.publishableClients =
      data.directory_submission_publishable_clients || [];
    flattened.confidentialClients =
      data.directory_submission_confidential_clients || [];
    flattened.matters = data.directory_matter || [];

    // Remove nested structures
    delete (flattened as any).directory_schedule_id;
    delete (flattened as any).directory_submission_overview;
    delete (flattened as any).directory_matter;

    return flattened as DirectorySubmissionFull;
  } catch (err) {
    console.error("Error during flattening:", err);
    throw err;
  }
}
