import { type TableRow } from "@/components/DirectorySubmissions/Form/types";

// Exporting functions from separate files
export { saveData } from "./saveData";
export { saveMatterData } from "./saveMatterData";
export { saveTableData } from "./saveTableData";
export { useFormValidation } from "./useFormValidation";
export { submissionToFormData } from "./submissionToFormData";
export { loadSubmissionData } from "./loadSubmissionData";
export { validateForm } from "./validateForm";
export { validateSubmission } from "./validateSubmission";

// defaultArrays remains in the main utils file
export const defaultArrays: Record<string, TableRow[]> = {
  A4: [{ id: 0, value: ["", "", ""] }],
  B2: [{ id: 0, value: ["", ""] }],
  B3: [{ id: 0, value: ["", ""] }],
  B7: [{ id: 0, value: ["", "", ""] }],
  B8: [{ id: 0, value: ["", "", ""] }],
  B9: [{ id: 0, value: ["", "", null, null, ""] }],
  C1: [{ id: 0, value: ["", "", ""] }],
  CC: [{ id: 0, value: ["", null] }],
  PC: [{ id: 0, value: ["", null] }],
};
